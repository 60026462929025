import { Badge, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { notification as notificationApi } from '../../../api';
import { useApi, useAuth, useInternationalization } from '../../../hooks';
import {
  MemberChangeRequestEntity,
  MemberCommitmentDocumentEntity,
  MemberEntity,
  NotificationEntity,
  VendorBuyingAgreementEntity,
  VendorChangeRequestEntity,
  VendorContactEntity,
  VendorRebateCategoryProgramEntity,
} from '../../../models';
import { routes } from '../../../routes';
import { palette } from '../../../styles/palette';
import {
  ContactType,
  NotificationEntityType,
  Styles,
  VendorBuyingAgreementStatus,
  VendorChangeRequestEntityType,
} from '../../../types';
import { formatDateDistance } from '../../../utils/formatters';
import { formatDateTime } from '../../../utils/helper';

const style: Styles = {
  listItem: {
    px: 3,
    py: 2,
    '&:hover': {
      backgroundColor: palette.grey[50],
      cursor: 'pointer',
    },
  },
  listItemBadge: {
    position: 'absolute',
    top: '10px',
    left: '10px',
  },
};

interface NotificationListItemProps {
  notification: NotificationEntity;
  onClick: () => void;
}

export const NotificationListItem = ({ notification, onClick }: NotificationListItemProps) => {
  const { isInternalUser, isMemberUser } = useAuth();
  const { t, currentLanguage, getTranslation } = useInternationalization();
  const { call: read } = useApi(notificationApi.read, null);
  const navigate = useNavigate();

  const onNotificationClick = (notification: NotificationEntity) => async () => {
    await read(notification.id);
    onClick();
    switch (notification.entityType) {
      case NotificationEntityType.VendorChangeRequest: {
        const vendorChangeRequest = notification.entity as VendorChangeRequestEntity;
        let route = routes.Admin.ChangeRequests.Vendor;
        if (!isInternalUser) {
          switch (vendorChangeRequest.entityType) {
            case VendorChangeRequestEntityType.VendorContact:
              route = isMemberUser ? routes.Member.BuyGuideDetail.Contacts : routes.Vendor.Contacts;
              break;
            case VendorChangeRequestEntityType.VendorDocument:
              route = isMemberUser ? routes.Member.BuyGuideDetail.Documents : routes.Vendor.Documents;
              break;
            case VendorChangeRequestEntityType.VendorLocation:
              route = isMemberUser ? routes.Member.BuyGuideDetail.Locations : routes.Vendor.Locations;
              break;
            case VendorChangeRequestEntityType.VendorInvoicingInformation:
            case VendorChangeRequestEntityType.VendorTaxInformation:
            case VendorChangeRequestEntityType.VendorPaymentSchedule:
              route = routes.Vendor.Financials;
              break;
          }
        }
        return navigate(generatePath(route.path, { vendorId: vendorChangeRequest.vendor.id }));
      }
      case NotificationEntityType.MemberChangeRequest: {
        const memberChangeRequest = notification.entity as MemberChangeRequestEntity;
        const route = isInternalUser ? routes.Admin.ChangeRequests.Member : routes.Member.Member.Detail;
        return navigate(generatePath(route.path, { memberId: memberChangeRequest.member.id }));
      }
      case NotificationEntityType.VendorBuyingAgreement: {
        const vba = notification.entity as VendorBuyingAgreementEntity;
        const route = isInternalUser ? routes.Admin.Vendor.BuyingAgreement : routes.Vendor.BuyingAgreement;
        return navigate(generatePath(route.path, { vendorId: vba.vendorId, vendorBuyingAgreementId: vba.id }));
      }
      case NotificationEntityType.MemberConfirmation: {
        const member = notification.entity as MemberEntity;
        return navigate(generatePath(routes.Member.Member.Detail.path, { memberId: member.id }));
      }
      case NotificationEntityType.VendorContactConfirmation: {
        return navigate(generatePath(routes.Vendor.Contacts.path));
      }
      case NotificationEntityType.MemberCommitmentDocument: {
        return navigate(generatePath(routes.Member.ForestProducts.Commitments.path));
      }
      case NotificationEntityType.RebateProgram: {
        const rebateProgram = notification.entity as VendorRebateCategoryProgramEntity;
        return navigate(
          generatePath(routes.Member.BuyGuideRebateProgramDetail.path, {
            vendorId: rebateProgram.rebateCategory.vendorId,
            rebateProgramId: rebateProgram.id,
          }),
        );
      }
    }
  };

  const renderNotificationMessage = (notification: NotificationEntity) => {
    switch (notification.entityType) {
      case NotificationEntityType.VendorChangeRequest: {
        const vendorChangeRequest = notification.entity as VendorChangeRequestEntity;
        const params = {
          name: vendorChangeRequest.vendor.name,
          entityType: t(`changeRequest:vendorEntityType.${vendorChangeRequest.entityType}`),
          status: t(`changeRequest:state.${notification.workflowStatus}`).toLowerCase(),
        };
        return isInternalUser
          ? t('notification:changeRequest.submitted', params)
          : isMemberUser
          ? t('notification:changeRequest.updated', params)
          : t('notification:changeRequest.approvedOrRejected', params);
      }
      case NotificationEntityType.MemberChangeRequest: {
        const memberChangeRequest = notification.entity as MemberChangeRequestEntity;
        const params = {
          name: memberChangeRequest.member.name,
          entityType: t(`changeRequest:memberEntityType.${memberChangeRequest.entityType}`),
          status: t(`changeRequest:state.${notification.workflowStatus}`).toLowerCase(),
        };
        return isInternalUser
          ? t('notification:changeRequest.submitted', params)
          : t('notification:changeRequest.approvedOrRejected', params);
      }
      case NotificationEntityType.VendorBuyingAgreement: {
        const vba = notification.entity as VendorBuyingAgreementEntity;
        const params = {
          name: vba.name,
          status: t(`vendor:buyingAgreements.status.${notification.workflowStatus}`).toLowerCase(),
        };
        return [VendorBuyingAgreementStatus.Approved, VendorBuyingAgreementStatus.Rejected].includes(
          notification.workflowStatus as VendorBuyingAgreementStatus,
        )
          ? t('notification:vba.approvedOrRejected', params)
          : t('notification:vba.inProgress', params);
      }
      case NotificationEntityType.MemberConfirmation: {
        const member = notification.entity as MemberEntity;
        return t('notification:member', { name: member.name });
      }
      case NotificationEntityType.VendorContactConfirmation: {
        const vendorContact = notification.entity as VendorContactEntity;
        return t('notification:vendorContact', {
          name:
            vendorContact.type === ContactType.Department
              ? getTranslation(vendorContact, 'department')
              : `${vendorContact.firstName} ${vendorContact.lastName}`,
        });
      }
      case NotificationEntityType.MemberCommitmentDocument: {
        const document = notification.entity as MemberCommitmentDocumentEntity;
        return t('notification:memberCommitmentDocument', { name: document.file?.name });
      }
      case NotificationEntityType.RebateProgram: {
        const rebateProgram = notification.entity as VendorRebateCategoryProgramEntity;
        return t('notification:rebateProgram', {
          vendor: rebateProgram.rebateCategory.vendor.name,
          name: getTranslation(rebateProgram, 'name'),
        });
      }
    }
  };

  return (
    <ListItem
      key={notification.id}
      divider
      sx={style.listItem}
      disablePadding
      disableGutters
      onClick={onNotificationClick(notification)}
    >
      <Badge
        sx={style.listItemBadge}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        overlap="circular"
        color="error"
        invisible={notification.isRead}
        variant="dot"
      />
      <Stack spacing={2}>
        <Typography whiteSpace="pre-wrap" variant="notificationMessage">
          {renderNotificationMessage(notification)}
        </Typography>
        <Tooltip arrow placement="right" title={formatDateTime(notification.createdDate)}>
          <Typography width="fit-content" variant="notificationTime">
            {formatDateDistance(new Date(notification.createdDate), currentLanguage)}
          </Typography>
        </Tooltip>
      </Stack>
    </ListItem>
  );
};
