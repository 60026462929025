import { VendorContactEmailAddressEntity, VendorContactPhoneNumberEntity, VendorContactRoleEntity } from '.';
import { ContactType } from '../../types';
import { PendingEntity } from '../PendingEntity';

export class VendorContactEntity extends PendingEntity {
  firstName: string;
  lastName: string;
  department_En: string;
  department_Fr: string;
  isVisibleToMember: boolean;
  type: ContactType;
  roleId: number | null;
  role: VendorContactRoleEntity | null;
  emailAddresses: VendorContactEmailAddressEntity[];
  phoneNumbers: VendorContactPhoneNumberEntity[];
  isArchived: boolean;
  mailSyncError: string;
  mailSyncDate: Date;
  readonly canConfirm: boolean;

  constructor(entity?: VendorContactEntity) {
    super(entity);
    if (entity) {
      Object.assign(this, { ...entity });
    } else {
      this.firstName = '';
      this.lastName = '';
      this.department_En = '';
      this.department_Fr = '';
      this.isVisibleToMember = false;
      this.type = ContactType.Person;
      this.roleId = null;
      this.role = null;
      this.emailAddresses = [];
      this.phoneNumbers = [];
    }
  }
}
