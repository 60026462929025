import { Breakpoint, ButtonProps } from '@mui/material';
import { SystemStyleObject, Theme } from '@mui/system';
import { Dispatch, SetStateAction } from 'react';
import { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { LayoutPage } from '../components/Layout';
import { MenuItemProps } from '../components/Menu/MenuItem';
import { TableProps } from '../components/Table/Table';
import { Result } from '../hooks/useApi';
import {
  CategorizationEntity,
  EventEntity,
  MemberChangeRequestEntity,
  ProfileEntity,
  RebateCategoryEntity,
  TargetedMembershipEntity,
  VendorDataReportEntity,
  VendorChangeRequestEntity,
  MissingTranslationReportEntity,
  TargetedMembershipMemberGroupEntity,
  TargetedMembershipMemberEntity,
} from '../models';

// Common

export enum CanadaProvinceOrTerritory {
  Alberta = 'AB',
  BritishColumbia = 'BC',
  Manitoba = 'MB',
  NewBrunswick = 'NB',
  NewfoundlandAndLabrador = 'NL',
  NovaScotia = 'NS',
  Ontario = 'ON',
  PrinceEdwardIsland = 'PE',
  Quebec = 'QC',
  Saskatchewan = 'SK',
  NorthwestTerritories = 'NT',
  Nunavut = 'NU',
  Yukon = 'YT',
}

export enum UnitedStatesStateOrTerritory {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
  DistrictOfColumbia = 'DC',
  AmericanSamoa = 'AS',
  Guam = 'GU',
  NorthernMarianaIslands = 'MP',
  PuertoRico = 'PR',
  UnitedStatesMinorOutlyingIslands = 'UM',
  UnitedStatesVirginIslands = 'VI',
}

export const Province = { ...CanadaProvinceOrTerritory, ...UnitedStatesStateOrTerritory };
export type Province = CanadaProvinceOrTerritory | UnitedStatesStateOrTerritory;

export enum Country {
  Canada = 'CA',
  UnitedStates = 'US',
}

export enum TimeZoneCode {
  ChamorroStandardTime = 'ChamorroStandardTime',
  SamoaStandardTime = 'SamoaStandardTime',
  HawaiiAleutianStandardTime = 'HawaiiAleutianStandardTime',
  HawaiiAleutianTime = 'HawaiiAleutianTime',
  AlaskaTime = 'AlaskaTime',
  PacificTime = 'PacificTime',
  MountainStandardTime = 'MountainStandardTime',
  MountainTime = 'MountainTime',
  CentralStandardTime = 'CentralStandardTime',
  CentralTime = 'CentralTime',
  EasternStandardTime = 'EasternStandardTime',
  EasternTime = 'EasternTime',
  AtlanticStandardTime = 'AtlanticStandardTime',
  AtlanticTime = 'AtlanticTime',
  NewfoundlandTime = 'NewfoundlandTime',
}

export enum FilterPeriod {
  All = 'All',
  Upcoming = 'Upcoming',
  Current = 'Current',
  Expired = 'Expired',
}

export type Styles = Record<string, SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)>;

export interface SelectOption<T> {
  label: string;
  value: T;
  groupName?: string;
  disabled?: boolean;
  description?: string;
  color?: string;
}

export type Authentication = {
  username: string;
  password: string;
};

export type PasswordReinitializationRequest = {
  email: string;
};

export type PasswordInitialization = {
  username: string;
  newPassword: string;
  newPasswordConfirmation: string;
  sessionToken: string;
};

export type PasswordReinitialization = {
  username: string;
  confirmationCode: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export type CodeVerificationRequest = {
  username: string;
  code?: string;
  sessionToken: string;
};

export type PhoneNumberInitializationRequest = {
  phoneNumber: string;
};

export type PhoneNumberVerificationRequest = {
  code: string;
};

export type User = {
  profile: ProfileEntity;
  accessToken: string;
  refreshToken: string;
  memberId: number | null;
  requiresVerificationPhoneNumber: boolean;
};

export type UserTemporaryPassword = {
  username: string;
  sessionToken: string;
};

export interface IconProps {
  color?: string;
}

// Entity

export interface Entity {
  id: number;
  createdDate?: Date;
  createdBy?: string;
  modifiedDate?: Date;
  modifiedBy?: string;
}

export interface Archivable {
  isArchived?: boolean;
}

export interface Pending {
  isPending?: boolean;
}

export type WithoutLanguageSuffix<T> = T extends `${infer P}_En` | `${infer P}_Fr` ? P : never;

export type EntityRelationship<T> = T extends `${infer P}` & `${infer P}Id` ? P : never;

export interface AllowedAccess {
  isAllowedAccess: boolean;
}

export interface TargetedEntity {
  targetedMembership: TargetedMembershipEntity;
}

export type DeletableEntity = Entity & { isDeleted?: boolean };

// Base Component List

export interface BaseComponentListProps<T extends Entity & Archivable & Pending, F extends PaginationFilter> {
  addButtonProps?: ButtonProps;
  tableProps?: Omit<TableProps<T>, 'data' | 'translationNamespace'>;
  showSearch?: boolean;
  onSave: (entity: T) => Promise<T | null>;
  filter: F;
  setFilter: Dispatch<SetStateAction<F>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchApi: Result<any[], PaginationEntity<T>>;
  layout?: React.ComponentProps<typeof LayoutPage>['display'];
  isChangeRequest?: boolean;
  hidePending?: boolean;
  readOnly?: boolean;
}

// Base Component Detail

export interface BaseComponentDetailProps<T extends Entity & Archivable & Pending> {
  onSave?: (entity: T) => Promise<T | null>;
  actionMenuItems?: (onClick: () => void) => React.ReactElement<MenuItemProps>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchApi: Result<any, T>;
  breadcrumbs?: Breadcrumb[];
  readOnly?: boolean;
}

// Categorization

export type CategorizationType =
  | 'region'
  | 'membershipType'
  | 'vendorDesignation'
  | 'rebateType'
  | 'productCategory'
  | 'board'
  | 'department';

export enum CategorizationSortOrder {
  NAME = 'Name',
  DISPLAYORDER = 'DisplayOrder',
}

export interface CategorizationSorting {
  sortOrder: CategorizationSortOrder;
}

export interface CategorizationList {
  sortOrder: CategorizationSortOrder;
  values: CategorizationEntity[];
}

export interface CategorizationDisplayOrder {
  id: number;
  displayOrder: number;
}

// Pagination

export interface PaginationEntity<T> {
  currentPage: number;
  data: T[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  totalPending?: number;
}

export interface PaginationFilter {
  pageNumber?: number;
  pageSize?: number;
  orderBy?: OrderBy[];
}

export interface OrderBy {
  columnName: string;
  direction: OrderDirection;
}

export type OrderDirection = 'asc' | 'desc';

// Filters

export interface ArchiveFilter {
  isArchived?: boolean;
}

export interface ContentFilterFilter {
  filter?: ContentFilter;
}

export interface MailSyncFilter {
  mailSyncErrorOnly?: boolean;
}

export interface PeriodFilter {
  period?: FilterPeriod;
}
// Vendor

export interface VendorFilter extends PaginationFilter, ArchiveFilter, MailSyncFilter {
  buyersIds?: number[];
  designations?: number[];
  isVisible?: boolean;
  isActive?: boolean;
  vendorNameOrNumber?: string;
}

export enum VendorBuyingAgreementType {
  FileBased = 'FileBased',
  InApp = 'InApp',
}

export enum VendorBuyingAgreementDocumentType {
  Legal = 'Legal',
  Other = 'Other',
  SignedAgreement = 'SignedAgreement',
  AddendumGeneralCondition = 'AddendumGeneralCondition',
  AddendumBuyOpportunity = 'AddendumBuyOpportunity',
  AddendumRebateCategory = 'AddendumRebateCategory',
}

export enum VendorBuyingAgreementDocumentStatus {
  None = 'None',
  PendingReview = 'PendingReview',
  Accepted = 'Accepted',
  Refused = 'Refused',
}

export enum VendorBuyingAgreementStatus {
  InProgressTBM = 'InProgressTBM',
  Approved = 'Approved',
  InProgressVendor = 'InProgressVendor',
  Rejected = 'Rejected',
}

export enum VendorBuyingAgreementTriggers {
  SendToVendor = 'SendToVendor',
  Recall = 'Recall',
  Approve = 'Approve',
  Reject = 'Reject',
  Submit = 'Submit',
}

export interface VendorBuyingAgreementFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {
  status?: VendorBuyingAgreementStatus[];
  responsibleBuyerId?: number;
}

export enum RebateTypeAndUnitType {
  Percent = 'Percent',
  Dollar = 'Dollar',
  Custom = 'Custom',
}

// Member

export interface MemberFilter extends PaginationFilter, ArchiveFilter, MailSyncFilter {
  memberGroupId?: number;
  buyersIds?: number[];
  membershipTypeIds?: number[];
  regionIds?: number[];
  isMarketing?: boolean;
  isActive?: boolean;
  memberNameOrNumber?: string;
  isMyLocation?: boolean;
}

// Member Group

export interface MemberGroupFilter extends PaginationFilter, ArchiveFilter {
  memberGroupName?: string;
  type?: MemberGroupType;
}

export enum MemberGroupType {
  General = 'General',
  Ownership = 'Ownership',
  DCAssociation = 'DCAssociation',
}

// Contacts

export enum ContactType {
  Person = 'Person',
  Department = 'Department',
}

export interface VendorContactFilter extends PaginationFilter, ArchiveFilter {}

export interface MemberContactFilter extends PaginationFilter, ArchiveFilter {}

export interface InternalContactFilter extends PaginationFilter, ArchiveFilter {
  name?: string;
  officeIds?: number[];
  boardIds?: number[];
  departmentIds?: number[];
}

export interface OfficeFilter extends PaginationFilter, ArchiveFilter {
  city?: string;
  officeDescription?: string;
  province?: Province;
}

// Locations

export interface VendorLocationFilter extends PaginationFilter, ArchiveFilter {}

// Documents

export enum DocumentType {
  PriceList = 'PriceList',
  Other = 'Other',
}

export interface VendorDocumentFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {
  type?: DocumentType;
  rebateCategoryIds?: number[];
  rebateProgramId?: number;
}

// General Conditions

export enum GeneralConditionType {
  CoOpAllowance = 'CoOpAllowance',
  DefectivePolicy = 'DefectivePolicy',
  Freight = 'Freight',
  InitialOrder = 'InitialOrder',
  MinimumOrder = 'MinimumOrder',
}

export interface UpdateGeneralConditionFileLanguageRequest {
  id?: number;
  languages: LanguageCode[];
}

export interface VendorGeneralConditionFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {
  type?: GeneralConditionType;
}

// User

export enum UserType {
  Internal = 'InternalUser',
  Member = 'MemberUser',
  Vendor = 'VendorUser',
}

export enum VerificationStatus {
  UnVerified = 'UnVerified',
  VerificationRequired = 'VerificationRequired',
  Verified = 'Verified',
}

export enum RoleGroup {
  Buyer = 'Buyer',
}

export interface UserFilter extends PaginationFilter, ArchiveFilter {
  userFirstNameOrLastName?: string;
  roleIds?: number[];
  roleGroup?: RoleGroup;
  isAllowedAccess?: boolean;
  isVerified?: boolean;
  vendorIds?: number[];
  memberIds?: number[];
  memberNameOrNumber?: string;
  vendorNameOrNumber?: string;
}

// Rebate Categories

export interface VendorRebateCategoryFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {}

export enum VendorRebateCategoryValue {
  ReceivableFrequencies = 'ReceivableFrequencies',
  PaymentMethods = 'PaymentMethods',
  CalculationMethods = 'CalculationMethods',
}

export interface VendorRebateCategoryProgramFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {
  rebateCategoryId?: number;
}

export enum VendorRebateCategoryProgramStatus {
  InProgress = 'InProgress',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Verified = 'Verified',
}

export enum VendorRebateCategoryProgramTriggers {
  Approve = 'Approve',
  Reject = 'Reject',
  Reopen = 'Reopen',
  Verify = 'Verify',
  Unverify = 'Unverify',
}

export enum RebateValueType {
  Receivable = 'Receivable',
  Payable = 'Payable',
  Holdback = 'Holdback',
  FOID = 'FOID',
}

export interface RebateCategoryDuplicate {
  name_En: RebateCategoryEntity['name_En'];
  name_Fr: RebateCategoryEntity['name_Fr'];
  buyingAgreementId: number;
  vendorId: number;
  rebateCategoryId: number;
  tierIds?: number[];
  growthIds?: number[];
  ModifyMembersAndMemberGroups?: boolean;
  memberGroups?: TargetedMembershipMemberGroupEntity[];
  members?: TargetedMembershipMemberEntity[];
}

//Buy Opportunities

export interface VendorBuyOpportunityFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {
  opportunity?: OpportunityType;
}

export enum OpportunityType {
  All = 'All',
  Booking = 'Booking',
  SpecialBuy = 'SpecialBuy',
  PoolBuy = 'PoolBuy',
}

// Error

export enum ApiErrorCodes {
  MEMBERS_INACTIVE_OR_ARCHIVED = 'MEMBERS_INACTIVE_OR_ARCHIVED',
  CATEGORY_ABBREVIATION_EN_NOT_UNIQUE = 'CATEGORY_ABBREVIATION_EN_NOT_UNIQUE',
  CATEGORY_ABBREVIATION_FR_NOT_UNIQUE = 'CATEGORY_ABBREVIATION_FR_NOT_UNIQUE',
  CATEGORY_NAME_EN_NOT_UNIQUE = 'CATEGORY_NAME_EN_NOT_UNIQUE',
  CATEGORY_NAME_FR_NOT_UNIQUE = 'CATEGORY_NAME_FR_NOT_UNIQUE',
  MEMBER_GROUP_NAME_NOT_UNIQUE = 'MEMBER_GROUP_NAME_NOT_UNIQUE',
  MEMBER_NAME_NOT_UNIQUE = 'MEMBER_NAME_NOT_UNIQUE',
  MEMBER_NUMBER_LOCATION_NOT_UNIQUE = 'MEMBER_NUMBER_LOCATION_NOT_UNIQUE',
  REBATE_CATEGORY_NAME_EN_NOT_UNIQUE = 'REBATE_CATEGORY_NAME_EN_NOT_UNIQUE',
  REBATE_CATEGORY_NAME_FR_NOT_UNIQUE = 'REBATE_CATEGORY_NAME_FR_NOT_UNIQUE',
  USER_CREATION_ERROR__USERNAME_EXISTS = 'USER_CREATION_ERROR : UsernameExists',
  USER_EMAIL_NOT_UNIQUE = 'USER_EMAIL_NOT_UNIQUE',
  VENDOR_BUYING_AGREEMENT_NAME_NOT_UNIQUE = 'VENDOR_BUYING_AGREEMENT_NAME_NOT_UNIQUE',
  VENDOR_NAME_NOT_UNIQUE = 'VENDOR_NAME_NOT_UNIQUE',
  VENDOR_NUMBER_NOT_UNIQUE = 'VENDOR_NUMBER_NOT_UNIQUE',
  TIER_NAME_EN_NOT_UNIQUE = 'TIER_NAME_EN_NOT_UNIQUE',
  TIER_NAME_FR_NOT_UNIQUE = 'TIER_NAME_FR_NOT_UNIQUE',
  GROWTH_NAME_EN_NOT_UNIQUE = 'GROWTH_NAME_EN_NOT_UNIQUE',
  GROWTH_NAME_FR_NOT_UNIQUE = 'GROWTH_NAME_FR_NOT_UNIQUE',
  USER_UNVERIFIED = 'USER_UNVERIFIED',
  UNHANDLED_MAILCHIMP_ERROR = 'UNHANDLED_MAILCHIMP_ERROR',
  COMMITMENT_EVENT_YEAR_NOT_UNIQUE = 'COMMITMENT_EVENT_YEAR_NOT_UNIQUE',
  COMMITMENT_EVENT_CATEGORY_NAME_EN_NOT_UNIQUE = 'COMMITMENT_EVENT_CATEGORY_NAME_EN_NOT_UNIQUE',
  COMMITMENT_EVENT_CATEGORY_NAME_FR_NOT_UNIQUE = 'COMMITMENT_EVENT_CATEGORY_NAME_FR_NOT_UNIQUE',
  FOREST_PRODUCT_PRICE_UPDATE_DATE_NOT_UNIQUE = 'FOREST_PRODUCT_PRICE_UPDATE_DATE_NOT_UNIQUE',
  USER_CREDENTIALS_CODE_MISMATCH = 'USER_CREDENTIALS_CODE_MISMATCH',
  USER_CREDENTIALS_NOT_AUTHORIZED = 'USER_CREDENTIALS_NOT_AUTHORIZED',
}

export interface ApiException {
  code: string;
}

// Forms

export type FormattedInput = {
  parsed: string | number;
  formatted: [string | undefined, string, string | undefined];
};

export type EntityFormOptions = {
  readOnly: boolean;
  variant?: 'drawer' | 'card' | 'details' | 'changeRequest';
  setIgnoreDirtySubmit: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
};

export type EntityFormContextType<T extends FieldValues = FieldValues> = UseFormReturn<T> & EntityFormOptions;

export interface EntityFormSchema<T extends Entity> {
  schema: AnyObjectSchema;
  errorCodeMap?: Partial<Record<ApiErrorCodes, { key: Path<T>; message: string }>>;
}

// Bulk Actions

export interface BulkAction<T extends Entity> {
  icon?: React.ReactNode;
  label: string;
  separator?: string;
  onConfirm: (items: T[]) => Promise<void>;
  permissions?: PermissionProps;
  disabled?: boolean;
}

// ConfirmationModal

export type ConfirmationModalRef = {
  waitForResponse: () => Promise<boolean>;
};

// Internationalization

export enum LanguageCode {
  fr = 'fr',
  en = 'en',
}

export enum TranslationLanguage {
  Fr = 'Fr',
  En = 'En',
}

export type TranslatedString<Key extends string, Lang extends TranslationLanguage> = `${Key}_${Lang}`;

export type TranslatedStrings<T extends string> = {
  [Key in TranslatedString<T, TranslationLanguage>]: string;
};

export type TranslationContext = 'male' | 'female';

//utils
export type BreakableProperty = {
  [key in Breakpoint]?: string;
};

// Permissions

export enum PermissionKey {
  AdminEditPermissions = 'AdminEditPermissions',
  AdminManageInternalUsers = 'AdminManageInternalUsers',
  AdminManageMemberUsers = 'AdminManageMemberUsers',
  AdminManageVendorUsers = 'AdminManageVendorUsers',
  AdminManageGeneralGroups = 'AdminManageGeneralGroups',
  AdminManageOwnerGroups = 'AdminManageOwnerGroups',
  AdminManageDCGroups = 'AdminManageDCGroups',
  MemberViewMembers = 'MemberViewMembers',
  MemberEditMembers = 'MemberEditMembers',
  MemberViewMemberUsers = 'MemberViewMemberUsers',
  MemberApproveMembers = 'MemberApproveMembers',
  VendorViewVendors = 'VendorViewVendors',
  VendorEditInfo = 'VendorEditInfo',
  VendorViewContacts = 'VendorViewContacts',
  VendorEditContacts = 'VendorEditContacts',
  VendorApproveContacts = 'VendorApproveContacts',
  VendorViewFinancial = 'VendorViewFinancial',
  VendorEditFinancial = 'VendorEditFinancial',
  VendorApproveFinancial = 'VendorApproveFinancial',
  VendorViewRebates = 'VendorViewRebates',
  VendorEditRebates = 'VendorEditRebates',
  VendorApproveRebates = 'VendorApproveRebates',
  VendorViewConditions = 'VendorViewConditions',
  VendorEditConditions = 'VendorEditConditions',
  VendorApproveConditions = 'VendorApproveConditions',
  VendorViewBuys = 'VendorViewBuys',
  VendorEditBuys = 'VendorEditBuys',
  VendorApproveBuys = 'VendorApproveBuys',
  VendorViewVBAs = 'VendorViewVBAs',
  VendorEditVBAs = 'VendorEditVBAs',
  VendorApproveVBAs = 'VendorApproveVBAs',
  VendorViewFiles = 'VendorViewFiles',
  VendorEditFiles = 'VendorEditFiles',
  VendorApproveFiles = 'VendorApproveFiles',
  VendorViewBuyGuide = 'VendorViewBuyGuide',
  VendorViewBlocks = 'VendorViewBlocks',
  VendorEditBlocks = 'VendorEditBlocks',
  VendorViewPrograms = 'VendorViewPrograms',
  CmsAdView = 'CmsAdView',
  CmsAdEdit = 'CmsAdEdit',
  CmsAdApprove = 'CmsAdApprove',
  CmsNewsView = 'CmsNewsView',
  CmsNewsEdit = 'CmsNewsEdit',
  CmsNewsApprove = 'CmsNewsApprove',
  CmsStaticView = 'CmsStaticView',
  CmsStaticEdit = 'CmsStaticEdit',
  CmsStaticApprove = 'CmsStaticApprove',
  CmsHelpView = 'CmsHelpView',
  CmsHelpEdit = 'CmsHelpEdit',
  CmsHelpApprove = 'CmsHelpApprove',
  AdminSupportMembers = 'AdminSupportMembers',
  MemberViewContacts = 'MemberViewContacts',
  MemberEditContacts = 'MemberEditContacts',
  MemberApproveContacts = 'MemberApproveContacts',
  AdminManageContacts = 'AdminManageContacts',
  CciViewCommitments = 'CciViewCommitments',
  CciEditCommitments = 'CciEditCommitments',
  CciManageEvents = 'CciManageEvents',
  AdminSettingsMailChimp = 'AdminSettingsMailChimp',
  AdminSettingsSearch = 'AdminSettingsSearch',
  CommodityGroupManage = 'CommodityGroupManage',
  CommodityPriceView = 'CommodityPriceView',
  CommodityPriceEdit = 'CommodityPriceEdit',
  MemberCommitmentsView = 'MemberCommitmentsView',
  MemberCommitmentsEdit = 'MemberCommitmentsEdit',
  ReportLogin = 'ReportLogin',
  ReportUsers = 'ReportUsers',
  ReportVendorData = 'ReportVendorData',
  ReportActivityLog = 'ReportActivityLog',
  VendorViewRebateTerms = 'VendorViewRebateTerms',
  VendorViewRebateFOID = 'VendorViewRebateFOID',
  VendorViewRebateMP = 'VendorViewRebateMP',
  VendorViewRebateQP = 'VendorViewRebateQP',
  VendorViewRebateSP = 'VendorViewRebateSP',
  VendorViewRebateAP = 'VendorViewRebateAP',
  VendorViewRebateTiersGrowths = 'VendorViewRebateTiersGrowths',
  VendorVerifyRebates = 'VendorVerifyRebates',
  AdminViewCategorization = 'AdminViewCategorization',
  AdminEditCategorization = 'AdminEditCategorization',
}

export enum PermissionCategory {
  Admin = 'Admin',
  Member = 'Member',
  Vendor = 'Vendor',
  Cms = 'Cms',
  Reporting = 'Reporting',
}

export interface PermissionProps {
  keys?: PermissionKey[] | PermissionKey;
  any?: boolean;
}

export enum PermissionState {
  Available = 'Available',
  Inherited = 'Inherited',
  Included = 'Included',
  Excluded = 'Excluded',
}

// Change Requests

export interface ChangeRequestContextType<T extends MemberChangeRequestEntity | VendorChangeRequestEntity> {
  changeRequest: T;
  toggleViewMode: (checked: boolean) => void;
  isAfter: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export enum ChangeRequestState {
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum ChangeRequestType {
  Create = 'Create',
  Update = 'Update',
  Archive = 'Archive',
}

export enum VendorChangeRequestEntityType {
  VendorContact = 'VendorContact',
  VendorLocation = 'VendorLocation',
  VendorInvoicingInformation = 'VendorInvoicingInformation',
  VendorPaymentSchedule = 'VendorPaymentSchedule',
  VendorTaxInformation = 'VendorTaxInformation',
  VendorDocument = 'VendorDocument',
}

export enum MemberChangeRequestEntityType {
  MemberGeneralInformation = 'MemberGeneralInformation',
  MemberContact = 'MemberContact',
}

export interface VendorChangeRequestFilter extends PaginationFilter {
  vendorId?: number;
  vendorNameOrNumber?: string;
  state?: ChangeRequestState;
  entityType?: VendorChangeRequestEntityType;
}

export interface MemberChangeRequestFilter extends PaginationFilter {
  memberNameOrNumber?: string;
  memberId?: number;
  state?: ChangeRequestState;
  entityType?: MemberChangeRequestEntityType;
}

export enum ChangeRequestTrigger {
  Approve = 'Approve',
  Reject = 'Reject',
}

//Buy Guide
export interface BuyGuideFilter extends PaginationFilter {
  vendorName?: string;
  productCategoryNameOrKeyword?: string;
  isFavourite?: boolean;
  designations?: number[];
}

export enum BuyGuideSearchBy {
  Vendor = 'Vendor',
  ProductCategories = 'ProductCategories',
}

// Change Log

export enum ChangeLogEntityType {
  Tiers = 'Tiers',
  Growth = 'Growth',
  Document = 'Document',
  GeneralConditions = 'GeneralConditions',
  BuyOpportunity = 'BuyOpportunity',
  RebateCategory = 'RebateCategory',
  VendorBuyingAgreement = 'VendorBuyingAgreement',
  RebateProgram = 'RebateProgram',
  Contact = 'Contact',
  Location = 'Location',
}

export enum ChangeLogActionType {
  Added = 'Added',
  Updated = 'Updated',
  Removed = 'Removed',
  Accepted = 'Accepted',
  Refused = 'Refused',
  SentToVendor = 'SentToVendor',
  Approved = 'Approved',
  Recalled = 'Recalled',
  Submitted = 'Submitted',
  Rejected = 'Rejected',
  AddedOrUpdated = 'AddedOrUpdated',
  RequestedChanges = 'RequestedChanges',
  Unpublished = 'Unpublished',
}

export enum ChangeLogType {
  All = 'All',
  ChangeLog = 'ChangeLog',
  Comment = 'Comment',
}

export interface ChangeLogFilter extends PaginationFilter {
  changeLogType: ChangeLogType;
}

export interface BuyGuideChangeLogFilter extends PaginationFilter {
  changeLogEntityTypes: BuyGuideChangeLogEntityType[];
}

export enum BuyGuideChangeLogEntityType {
  RebateProgram = 'RebateProgram',
  GeneralConditions = 'GeneralConditions',
  Document = 'Document',
  Contact = 'Contact',
  Location = 'Location',
  BuyOpportunity = 'BuyOpportunity',
}

// Tiers and Growths

export interface SearchVendorTiersGrowthsFilter extends PaginationFilter, ArchiveFilter {
  name?: string;
}

export interface GrowthOrTierOption {
  name_En: string;
  name_Fr: string;
  type: 'tier' | 'growth';
  id: number;
}

// Breadcrumbs

export interface Breadcrumb {
  title?: string;
  href?: string;
}

// Cms

export enum ContentStatus {
  InProgress = 'InProgress',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum AdType {
  Image = 'Image',
  Video = 'Video',
}

export enum HelpTrainingType {
  Help = 'Help',
  Training = 'Training',
}

export enum ContentFilter {
  Upcoming = 'Upcoming',
  Active = 'Active',
  Published = 'Published',
  Drafts = 'Drafts',
  Expired = 'Expired',
  Rejected = 'Rejected',
}

export interface AdFilter extends PaginationFilter, ArchiveFilter, ContentFilterFilter {
  adName?: string;
  type?: AdType;
  status?: ContentStatus;
}

export enum EventType {
  Publication = 'Publication',
  PoolBuy = 'PoolBuy',
  Booking = 'Booking',
  SpecialBuy = 'SpecialBuy',
  CCI = 'CCI',
}

export interface EventFilter extends PaginationFilter {
  favouriteVendorsOnly?: boolean;
  types?: EventType[];
  effectiveDate?: string;
  expiryDate?: string;
}

export interface EventPaginationEntity extends PaginationEntity<EventEntity> {
  hasFavouriteVendors: boolean;
}

export enum EventListDateRange {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
}

export interface PublicationFilter extends PaginationFilter, ArchiveFilter, ContentFilterFilter {
  publicationName?: string;
  publicationCategoryId?: number;
  urgency?: string;
  isMandatory?: boolean;
  status?: ContentStatus;
  vendorId?: number;
  vendorNameOrNumber?: string;
  isMandatoryOrFavouriteVendor?: boolean;
  isMandatoryOrFavouriteVendorOrNoVendor?: boolean;
}

export enum PublicationUrgency {
  Green = 'Green',
  Yellow = 'Yellow',
  Red = 'Red',
}

export interface HelpTrainingFilter extends PaginationFilter, ContentFilterFilter {
  helpTrainingNameOrKeyword?: string;
  status?: ContentStatus;
  type?: HelpTrainingType;
  isVisibleToAdmins?: boolean;
  isVisibleToVendors?: boolean;
  isVisibleToMembers?: boolean;
  isReadOnly: boolean;
}

export interface UpdateContentFileLanguageRequest {
  id?: number;
  languages: LanguageCode[];
}

export enum ContentBlockType {
  HTML = 'HTML',
  Image = 'Image',
  Video = 'Video',
}

export enum ContentTriggers {
  RequestChanges = 'RequestChanges',
  Approve = 'Approve',
  Reject = 'Reject',
  Submit = 'Submit',
  Unpublish = 'Unpublish',
}

export interface StaticPageFilter extends PaginationFilter {
  staticPageName?: string;
  filter?: ContentFilter;
  status?: ContentStatus;
}

// Notification

export interface NotificationFilter extends PaginationFilter {
  isRead?: boolean;
}

export enum NotificationEntityType {
  VendorBuyingAgreement = 'VendorBuyingAgreement',
  MemberChangeRequest = 'MemberChangeRequest',
  VendorChangeRequest = 'VendorChangeRequest',
  MemberConfirmation = 'MemberConfirmation',
  VendorContactConfirmation = 'VendorContactConfirmation',
  MemberCommitmentDocument = 'MemberCommitmentDocument',
  RebateProgram = 'RebateProgram',
}

export enum NotificationType {
  VendorBuyingAgreement = 'VendorBuyingAgreement',
  Member = 'Member',
  Vendor = 'Vendor',
  MemberConfirmation = 'MemberConfirmation',
  VendorContactConfirmation = 'VendorContactConfirmation',
  MemberCommitmentDocument = 'MemberCommitmentDocument',
}

export enum NotificationMode {
  None = 'None',
  InApp = 'InApp',
}

export enum NotificationEmailFrequency {
  Daily = 'Daily',
  ThreeDays = 'ThreeDays',
  Weekly = 'Weekly',
}

// CCI

export enum MemberCommitmentReportGrouping {
  None = 'None',
  Category = 'Category',
  Member = 'Member',
  Vendor = 'Vendor',
}

export interface CommitmentEventFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {}

export interface CommitmentEventCategoryFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {}

export interface MemberCommitmentsFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {
  memberId: number;
}

export interface MemberCommitmentReportFilter extends PaginationFilter {
  grouping: MemberCommitmentReportGrouping;
  memberNameOrNumber?: string;
  vendorNameOrNumber?: string;
  vendorId?: number;
  memberId?: number;
  commitmentEventIds?: number[];
  commitmentEventCategoryIds?: number[];
  commitmentCategoryIds?: number[];
  regionIds?: number[];
}

export enum ExportType {
  CSV = 'csv',
  PDF = 'pdf',
}

//forest product

export interface ForestProductGroupFilter extends PaginationFilter, ArchiveFilter {}
export interface ForestProductFilter extends PaginationFilter, ArchiveFilter {}
export interface ForestProductPriceUpdateFilter extends PaginationFilter, ArchiveFilter {}

export enum ForestProductIndexPrice {
  Toronto = 'Toronto',
  Vancouver = 'Vancouver',
}

//Commitments

export interface MemberCommitmentDocumentFilter extends PaginationFilter, ArchiveFilter, PeriodFilter {}

export enum MemberCommitmentDocumentType {
  ForestProducts = 'ForestProducts',
}

// Authentication Logs

export interface AuthenticationLogFilter extends PaginationFilter, PeriodFilter {
  userType?: UserType;
  hasUser?: boolean;
  types?: AuthenticationLogType[];
  effectiveDate?: string;
  expiryDate?: string;
}

export enum AuthenticationLogType {
  UnknownUser = 'UnknownUser',
  IncorrectPassword = 'IncorrectPassword',
  Failed2FA = 'Failed2FA',
  Archived = 'Archived',
  AccessDenied = 'AccessDenied',
  Unverified = 'Unverified',
}

// Activity Logs

export interface ActivityLogFilter extends PaginationFilter, PeriodFilter {
  effectiveDate?: string;
  expiryDate?: string;
  entityGroupType?: string;
}

export enum ActivityLogEntityTypeGroup {
  Categorization = 'Categorization',
  CCI = 'CCI',
  Contact = 'Contact',
  Content = 'Content',
  ForestProduct = 'ForestProduct',
  Member = 'Member',
  User = 'User',
  Vendor = 'Vendor',
}

export enum ActivityLogEntityType {
  Ad = 'Ad',
  BoardValue = 'BoardValue',
  CommitmentEvent = 'CommitmentEvent',
  CommitmentEventCategory = 'CommitmentEventCategory',
  DepartmentValue = 'DepartmentValue',
  ForestProduct = 'ForestProduct',
  ForestProductGroup = 'ForestProductGroup',
  ForestProductPriceUpdate = 'ForestProductPriceUpdate',
  Growth = 'Growth',
  HelpTraining = 'HelpTraining',
  InternalContact = 'InternalContact',
  Member = 'Member',
  MemberChangeRequest = 'MemberChangeRequest',
  MemberCommitment = 'MemberCommitment',
  MemberCommitmentDocument = 'MemberCommitmentDocument',
  MemberContact = 'MemberContact',
  MemberContactChangeRequest = 'MemberContactChangeRequest',
  MemberGeneralInformationChangeRequest = 'MemberGeneralInformationChangeRequest',
  MemberGroup = 'MemberGroup',
  MembershipTypeValue = 'MembershipTypeValue',
  Office = 'Office',
  ProductCategoryValue = 'ProductCategoryValue',
  Publication = 'Publication',
  RebateCategory = 'RebateCategory',
  RebateProgram = 'RebateProgram',
  RebateTypeValue = 'RebateTypeValue',
  RegionValue = 'RegionValue',
  StaticPage = 'StaticPage',
  Tier = 'Tier',
  User = 'User',
  Vendor = 'Vendor',
  VendorBlockedMember = 'VendorBlockedMember',
  VendorBlockedMemberGroup = 'VendorBlockedMemberGroup',
  VendorBuyingAgreement = 'VendorBuyingAgreement',
  VendorBuyingAgreementBuyOpportunity = 'VendorBuyingAgreementBuyOpportunity',
  VendorBuyingAgreementFile = 'VendorBuyingAgreementFile',
  VendorBuyingAgreementGeneralCondition = 'VendorBuyingAgreementGeneralCondition',
  VendorBuyingAgreementRebateCategory = 'VendorBuyingAgreementRebateCategory',
  VendorBuyOpportunity = 'VendorBuyOpportunity',
  VendorChangeRequest = 'VendorChangeRequest',
  VendorContact = 'VendorContact',
  VendorContactChangeRequest = 'VendorContactChangeRequest',
  VendorDesignationValue = 'VendorDesignationValue',
  VendorDocument = 'VendorDocument',
  VendorDocumentChangeRequest = 'VendorDocumentChangeRequest',
  VendorGeneralCondition = 'VendorGeneralCondition',
  VendorInvoicingInformation = 'VendorInvoicingInformation',
  VendorInvoicingInformationChangeRequest = 'VendorInvoicingInformationChangeRequest',
  VendorLocation = 'VendorLocation',
  VendorLocationChangeRequest = 'VendorLocationChangeRequest',
  VendorPaymentSchedule = 'VendorPaymentSchedule',
  VendorPaymentScheduleChangeRequest = 'VendorPaymentScheduleChangeRequest',
  VendorTaxInformation = 'VendorTaxInformation',
  VendorTaxInformationChangeRequest = 'VendorTaxInformationChangeRequest',
}

export enum ActivityLogEntityAction {
  Archive = 'Archive',
  Unarchive = 'Unarchive',
  Edit = 'Edit',
  Add = 'Add',
  Remove = 'Remove',
  Approve = 'Approve',
  Reject = 'Reject',
  Reopen = 'Reopen',
  Submit = 'Submit',
  Transfer = 'Transfer',
  RequestChanges = 'RequestChanges',
  Unpublish = 'Unpublish',
  Activate = 'Activate',
  Deactivate = 'Deactivate',
  SendToVendor = 'SendToVendor',
  Recall = 'Recall',
}

// Reports
export interface VendorDataReportFilter extends PaginationFilter, PeriodFilter {
  vendorNameOrNumber?: string;
  buyersIds?: number[];
  productCategories?: number[];
  status?: VendorRebateCategoryProgramStatus[];
  targetsAll?: boolean;
  custom?: boolean;
}

export interface ReportPaginationEntity
  extends PaginationEntity<VendorDataReportEntity | MissingTranslationReportEntity> {
  responsibleBuyersForFilter: { displayName: string; id: number }[];
}

export interface MissingTranslationPaginationEntity extends PaginationEntity<MissingTranslationReportEntity> {
  responsibleBuyersForFilter: { displayName: string; id: number }[];
}
export interface MissingTranslationsReportFilter extends PaginationFilter {
  vendorNameOrNumber?: string;
  buyersIds?: number[];
  entityType?: MissingTranslationEntityType;
}

export enum MissingTranslationEntityType {
  RebateCategory = 'RebateCategory',
  TiersAndGrowths = 'TiersAndGrowths',
  RebateProgram = 'RebateProgram',
  GeneralCondition = 'GeneralCondition',
}

export enum MissingTranslationField {
  ProgramName = 'ProgramName',
  NoteForMembers = 'NoteForMembers',
  CalculationMethod_NoteForMembers = 'CalculationMethod_NoteForMembers',
  PayableRebates_RebateNote = 'PayableRebates_RebateNote',
  PayableRebates_NoteForMembers = 'PayableRebates_NoteForMembers',
  PayableRebates_CustomFromToUnit = 'PayableRebates_CustomFromToUnit',
  PaymentTerms_TermNote = 'PaymentTerms_TermNote',
  PaymentTerms_NoteForMembers = 'PaymentTerms_NoteForMembers',
  PaymentTerms_CustomUnit = 'PaymentTerms_CustomUnit',
  PaymentTerms_TBMPaymentDate = 'PaymentTerms_TBMPaymentDate',
  PaymentTerms_MemberPaymentDate = 'PaymentTerms_MemberPaymentDate',
  Tier_Name = 'Tier_Name',
  Growth_Name = 'Growth_Name',
  TierGrowth_NoteForMembers = 'TierGrowth_NoteForMembers',
  TierGrowth_CustomFromToUnit = 'TierGrowth_CustomFromToUnit',
  TierGrowth_CustomRebateUnit = 'TierGrowth_CustomRebateUnit',
  Summary = 'Summary',
  File = 'File',
}

export interface HoldbackReportFilter extends PaginationFilter {
  vendorNameOrNumber?: string;
  year: number;
}

// Search

export enum SearchCategory {
  Vendors = 'Vendors',
  Members = 'Members',
  EventsAndPublications = 'EventsAndPublications',
  InternalContacts = 'InternalContacts',
  ForestProducts = 'ForestProducts',
  RebatePrograms = 'RebatePrograms',
}

export enum SearchPeriod {
  PreviousMonth = 'PreviousMonth',
  PreviousSixMonths = 'PreviousSixMonths',
  PreviousYear = 'PreviousYear',
  MoreThanOneYearAgo = 'MoreThanOneYearAgo',
}

export enum SearchSort {
  MostRelevant = 'MostRelevant',
  MostRecent = 'MostRecent',
}

export interface SearchRequest extends PaginationFilter {
  query: string;
  period?: SearchPeriod;
  sort: SearchSort;
  categories?: SearchCategory[];
}

export enum EntityType {
  Vendor = 'Vendor',
  VendorBuyingAgreement = 'VendorBuyingAgreement',
  VendorContact = 'VendorContact',
  VendorGeneralCondition = 'VendorGeneralCondition',
  VendorGeneralConditionFile = 'VendorGeneralConditionFile',
  VendorLocation = 'VendorLocation',
  Member = 'Member',
  MemberContact = 'MemberContact',
  Office = 'Office',
  InternalContact = 'InternalContact',
  VendorDocument = 'VendorDocument',
  RebateCategory = 'RebateCategory',
  RebateProgram = 'RebateProgram',
  VendorBuyingAgreementGeneralCondition = 'VendorBuyingAgreementGeneralCondition',
  VendorBuyingAgreementGeneralConditionFile = 'VendorBuyingAgreementGeneralConditionFile',
  VendorBuyingAgreementBuyOpportunity = 'VendorBuyingAgreementBuyOpportunity',
  VendorBuyingAgreementBuyOpportunityFile = 'VendorBuyingAgreementBuyOpportunityFile',
  VendorBuyingAgreementRebateCategory = 'VendorBuyingAgreementRebateCategory',
  VendorBuyingAgreementFile = 'VendorBuyingAgreementFile',
  VendorGrowth = 'VendorGrowth',
  VendorTier = 'VendorTier',
  Publication = 'Publication',
  PublicationFile = 'PublicationFile',
  StaticPage = 'StaticPage',
  StaticPageFile = 'StaticPageFile',
  VendorBuyOpportunity = 'VendorBuyOpportunity',
  VendorBuyOpportunityFile = 'VendorBuyOpportunityFile',
  HelpTraining = 'HelpTraining',
  HelpTrainingFile = 'HelpTrainingFile',
  ForestProductGroup = 'ForestProductGroup',
}

export interface RouteData {
  vendorId?: number;
  vendorBuyingAgreementId?: number;
  memberId?: number;
  officeId?: number;
  contactId?: number;
  vendorRebateCategoryId?: number;
  buyOpportunityId?: number;
  rebateProgramId?: number;
  publicationId?: number;
  staticPageId?: number;
  helpTrainingId?: number;
  vendorBuyOpportunityId?: number;
  vendorGeneralConditionId?: number;
  productGroupId?: number;
}

export enum TaskResult {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Failed = 'Failed',
}
