import { useState } from 'react';
import { vendor as vendorApi } from '../../../api';
import { DEFAULT_PAGINATION_FILTER } from '../../../constants';
import { useApi, useConfirmationModal, useInternationalization } from '../../../hooks';
import { VendorContactEntity } from '../../../models';
import { PaginationFilter } from '../../../types';
import { MenuItem } from '../../Menu';
import { VendorContactsList } from './VendorContactsList';
import { Button } from '@mui/material';
import { TableColumn } from '../../Table';

export const VendorContactsListVendor = () => {
  const { t } = useInternationalization();
  const [filter, setFilter] = useState<PaginationFilter>(DEFAULT_PAGINATION_FILTER);

  const getCurrentContactsApi = useApi(vendorApi.getCurrentContacts, null, filter);
  const { call: confirmContact } = useApi(vendorApi.confirmContact, { successKey: 'common:success.save' });
  const { call: createRequest } = useApi(vendorApi.createContactChangeRequest, { successKey: 'common:success.save' });
  const { call: updateRequest } = useApi(vendorApi.updateContactChangeRequest, { successKey: 'common:success.save' });
  const { call: removeRequest } = useApi(vendorApi.removeContactChangeRequest, { successKey: 'common:success.save' });
  const { openModal } = useConfirmationModal();

  const onDelete = async (entity: VendorContactEntity) => {
    await removeRequest(entity.id);
    await getCurrentContactsApi.refresh();
  };

  const onSave = async (entity: VendorContactEntity) => {
    return entity.id ? await updateRequest(entity) : await createRequest(entity);
  };

  const onConfirmContact = (entity: VendorContactEntity) => {
    openModal({
      confirmText: t('common:action.confirmButton', { action: t('common:confirm') }),
      title: t('vendor:contact.confirmGeneralInformationTitle'),
      description: t('vendor:contact.confirmGeneralInformationDescription'),
      onConfirm: async () => {
        await confirmContact(entity);
        await getCurrentContactsApi.refresh();
      },
    });
  };

  const renderActionsMenuItems = (entity: VendorContactEntity, onClick: () => void) => [
    ...(entity.canConfirm
      ? [
          <MenuItem
            id="confirm"
            key="confirm"
            onClick={() => {
              onConfirmContact(entity);
              onClick();
            }}
          >
            {t('common:confirm')}
          </MenuItem>,
        ]
      : []),
    <MenuItem
      id="remove"
      key="remove"
      onClick={() => {
        onDelete(entity);
        onClick();
      }}
    >
      {t('common:changeRequest.remove')}
    </MenuItem>,
  ];

  const renderConfirm = (entity: VendorContactEntity) =>
    entity.canConfirm ? <Button onClick={() => onConfirmContact(entity)}>{t('common:confirm')}</Button> : false;

  return (
    <VendorContactsList
      isChangeRequest
      layout="Page"
      filter={filter}
      setFilter={setFilter}
      fetchApi={getCurrentContactsApi}
      onSave={onSave}
      tableProps={{
        actionMenuItems: renderActionsMenuItems,
        children: <TableColumn type="custom" id="confirm" align="center" render={renderConfirm} />,
      }}
    />
  );
};
