import axios from 'axios';
import { VendorContactEntity, VendorContactRoleEntity, VendorEntityChangeRequestEntity } from '../../models';
import { ChangeRequestTrigger, PaginationEntity, PaginationFilter, VendorContactFilter } from '../../types';
import { getContentDispositionFileName } from '../../utils/file';

export const contact = {
  getContactRoles: async (): Promise<VendorContactRoleEntity[]> => {
    const { data } = await axios.get(`api/vendor/contactRoles`);
    return data.map((v: VendorContactRoleEntity) => new VendorContactRoleEntity(v));
  },
  getAllContacts: async (
    vendorId: number,
    contactFilter: VendorContactFilter,
  ): Promise<PaginationEntity<VendorContactEntity>> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/contacts/search`, contactFilter);
    return { ...data, data: data.data.map((v: VendorContactEntity) => new VendorContactEntity(v)) };
  },
  createContact: async (vendorId: number, entity: VendorContactEntity): Promise<VendorContactEntity> => {
    const { data } = await axios.post(`api/vendor/${vendorId}/contacts`, entity);
    return new VendorContactEntity(data);
  },
  updateContact: async (vendorId: number, entity: VendorContactEntity): Promise<VendorContactEntity> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/contacts/${entity.id}`, entity);
    return new VendorContactEntity(data);
  },
  archiveContact: async (vendorId: number, ids: number[], isArchived: boolean): Promise<VendorContactEntity[]> => {
    const { data } = await axios.put(`api/vendor/${vendorId}/contacts/archive`, { isArchived, ids });
    return data.map((v: VendorContactEntity) => new VendorContactEntity(v));
  },
  getCurrentContacts: async (contactFilter: PaginationFilter): Promise<PaginationEntity<VendorContactEntity>> => {
    const { data } = await axios.post(`api/vendor/contacts/search`, contactFilter);
    return { ...data, data: data.data.map((v: VendorContactEntity) => new VendorContactEntity(v)) };
  },
  createContactChangeRequest: async (entity: VendorContactEntity): Promise<VendorContactEntity> => {
    const { data } = await axios.post(`api/vendor/contacts`, entity);
    return new VendorContactEntity(data);
  },
  updateContactChangeRequest: async (entity: VendorContactEntity): Promise<VendorContactEntity> => {
    const { data } = await axios.put(`api/vendor/contacts/${entity.id}`, entity);
    return new VendorContactEntity(data);
  },
  removeContactChangeRequest: async (id: number): Promise<VendorContactEntity> => {
    const { data } = await axios.delete(`api/vendor/contacts/${id}`);
    return new VendorContactEntity(data);
  },
  approvedOrRejectContactRequest: async (
    id: number,
    trigger: ChangeRequestTrigger,
    entity: VendorContactEntity,
  ): Promise<VendorContactEntity> => {
    const { data } = await axios.post(`api/vendorChangeRequests/contacts/${id}/${trigger}`, entity);
    return new VendorContactEntity(data);
  },
  getContactChangeRequest: async (id: number): Promise<VendorEntityChangeRequestEntity<VendorContactEntity>> => {
    const { data } = await axios.get(`api/vendorChangeRequests/contacts/${id}`);
    return new VendorEntityChangeRequestEntity(data);
  },
  exportAllContacts: async (vendorId: number): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/vendor/${vendorId}/contacts/export`, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  exportAllVendorsContacts: async (): Promise<{ data: Blob; filename?: string }> => {
    const result = await axios.get(`api/vendor/contacts/export`, {
      responseType: 'blob',
    });
    return { data: result.data, filename: getContentDispositionFileName(result.headers) };
  },
  mailSyncContacts: async (vendorId: number, ids: number[]): Promise<void> => {
    await axios.put(`api/vendor/${vendorId}/contacts/mailsync`, { ids });
  },
  confirmContact: async (entity: VendorContactEntity): Promise<VendorContactEntity> => {
    const { data } = await axios.put(`api/vendor/contacts/${entity.id}/confirm`);
    return new VendorContactEntity(data);
  },
};
